import React from "react";

interface OneBtcLogoProps {
  width: string;
  height: string;
}

const OneBtcLogo: React.FC<OneBtcLogoProps> = ({ width, height }) => {
  return (
    <svg
      viewBox="0 0 1554 1554"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle id="Oval" fill="#F27400" cx="50%" cy="50%" r="50%"></circle>
        <g
          id="Group"
          transform="translate(265.162753, 171.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <path
            d="M723.073509,293.403528 L760.275278,143.710173 L669.443976,121.001837 L633.225286,266.750429 C609.03209,260.787212 584.781668,255.060115 560.477459,249.56995 L596.954854,102.86112 L506.149423,80.1527834 L468.947653,229.794233 C403.667775,297.720274 370.657026,338.136788 369.915405,351.043774 L327.539537,521.576893 L267.985662,761.117403 C263.484196,772.328833 252.075308,789.145978 226.334168,782.761691 C227.265505,784.085263 160.338539,766.230022 160.338539,766.230022 L115.454547,869.694616 L298.202398,916.883615 L260.612571,1068.28983 L351.340391,1090.99816 L388.54216,941.175047 C413.326093,947.948619 437.385652,954.151239 460.9278,960.042431 L423.829513,1109.13888 L514.634944,1131.84722 L552.250642,980.726481 C707.137285,1010.13053 823.606246,998.270293 872.63083,857.764083 C912.135074,744.611686 870.664673,679.341439 789.172619,636.753576 C848.519531,622.998812 893.223743,583.836664 905.15004,502.943081 C921.629545,392.437827 837.757405,333.032819 723.073509,293.403528 Z M697.895939,795.584198 C669.808745,908.887262 479.773637,847.662028 418.163018,832.277759 L468.072797,631.424691 C529.683416,646.886921 727.251587,677.447563 697.895939,795.584198 Z M725.433265,501.991723 C699.76569,604.610637 541.352677,552.460041 489.965673,539.674734 L535.285795,358.247599 C586.672799,371.032907 752.16384,394.895363 725.433265,501.965841 L725.433265,501.991723 Z"
            id="Shape"
            transform="translate(511.337247, 606.000000) rotate(-14.000000) translate(-511.337247, -606.000000) "
          ></path>
          <g id="1" transform="translate(162.837247, 248.000000)">
            <path
              d="M278,636 L149.759874,636 L149.759874,197.37931 L0,197.37931 L0,112.286897 C39.5260664,110.532414 67.1943128,107.90069 83.0047393,104.391724 C108.184308,98.8358621 128.679305,87.7241379 144.489731,71.0565517 C155.322801,59.6524138 163.5208,44.4468966 169.083728,25.44 C172.304371,14.0358621 173.914692,5.55586207 173.914692,0 L278,0 L278,636 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default OneBtcLogo;
