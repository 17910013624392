import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Code,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useControllableState,
  useDisclosure,
} from "@chakra-ui/react";
import { atom, useAtom, useAtomValue } from "jotai";
import { activeTabAtom, stxAddressAtom } from "../../constants";
// import OpenAI from "openai";
import SignIn from "../auth/sign-in";
import { useClipboardToast } from "../../hooks/use-clipboard-toast";

function Content() {
  const stxAddress = useAtomValue(stxAddressAtom);
  const [activeTab, setActiveTab] = useAtom(activeTabAtom);

  if (!stxAddress) {
    return (
      <Box width="100%" maxW="1200px">
        <Stack gap={8}>
          <Heading>1btc Permawall</Heading>
          <SignIn />
        </Stack>
      </Box>
    );
  }

  return (
    <Box width="100%" maxW="1200px">
      <Tabs
        variant="enclosed"
        colorScheme="orange"
        onChange={(index) => setActiveTab(index)}
        defaultIndex={activeTab}
        isFitted
      >
        <TabList>
          <Tab>Home</Tab>
          <Tab>Create HTML</Tab>
          <Tab isDisabled fontSize="sm">
            Active Tab: {activeTab}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Heading>1btc Permawall</Heading>
            <Text pb={4}>Convert chats into HTML for inscriiiiiiiiibing</Text>
          </TabPanel>
          <TabPanel>
            <CreateHTML />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

const formattedTextAtom = atom("");

function CreateHTML() {
  const [text, setText] = useControllableState({ defaultValue: "" });
  const [formattedText, setFormattedText] = useAtom(formattedTextAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const copyText = useClipboardToast();

  return (
    <Stack direction="column">
      <FormControl id="chat-content">
        <FormLabel>Chat contents:</FormLabel>
        <Textarea
          minH="50vw"
          placeholder="Paste here"
          onChange={(e) => setText(e.target.value.trim())}
        />
        <FormHelperText>
          Copy and paste the chat content from Console.
        </FormHelperText>
      </FormControl>
      <Button
        title="Convert to HTML"
        variant="1btc-orange"
        onClick={() => {
          setFormattedText(text);
          onOpen();
        }}
      >
        Convert to HTML
      </Button>
      <Modal
        allowPinchZoom
        autoFocus
        onClose={onClose}
        isOpen={isOpen}
        size={["full", "full", "xl"]}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading>Ready to Inscribe</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDir="column">
            <Code
              display="block"
              whiteSpace="pre-wrap"
              px={2}
              mb={6}
              children={formattedText}
            ></Code>
            <Box
              display="flex"
              flexDir={["column-reverse", "row"]}
              alignContent="center"
              alignItems="space-between"
              justifyContent="space-between"
              mb={6}
            >
              <Text>
                You can upload an inscription using ord or through a service
                that supports text inscriptions.
              </Text>
              <Button
                mx={3}
                mb={[6, 0]}
                whiteSpace="nowrap"
                onClick={() => copyText(formattedText)}
                minW="fit-content"
                borderRadius="xl"
              >
                Copy to Clipboard
              </Button>
            </Box>
            <Alert status="warning" flexShrink={0}>
              <AlertIcon />
              <AlertDescription>
                Remember,{" "}
                <Text as="b" title="Do Your Own Research">
                  always DYOR
                </Text>{" "}
                before using a tool or service!
              </AlertDescription>
            </Alert>
            <Text mb={6}>
              Use the "plain text" inscription type if you're using a service,
              or make sure the file's type is `.txt` if using the Ordinals CLI.
            </Text>
            <Text>
              See the{" "}
              <Link
                href="https://github.com/neu-fi/awesome-ordinals"
                isExternal
              >
                Ordinals Awesome List
              </Link>{" "}
              for even more inscription services.{" "}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose} size="md" borderRadius="xl">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

//function formatTextWithGpt(text: string) {
//  const openai = OpenAI({ apiKey: process.env.OPENAI_KEY })
//
//  const systemMessage =
//    "You are helping the user convert the provided text into HTML that resembles a web chat room. It should clearly display the user name, profile picture, timestamp, date, and contents as a chat conversation.";
//  return systemMessage;
//}

export default Content;
